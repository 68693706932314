import React, { useEffect, useState} from 'react';

const Kommuner = ({disabled, fylke, setValgtKommune}) => {

    const [kommuner, setKommuner] = useState([]);

    function formatYMD(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth()+1).toString();
        var dd  = date.getDate().toString();
        return yyyy + '-' + (mm[1]?mm:'0'+mm[0]) + '-' + (dd[1]?dd:'0'+dd[0]);
    }

    useEffect(() => {
        async function getKommuner() {
            const response = await fetch('https://data.ssb.no/api/klass/v1/classifications/131/codesAt.json?date=' + formatYMD(new Date()) + '&selectCodes=' + fylke + '*');
            const kommunerBody = await response.json();
            kommunerBody.codes.sort(function(a,b) {
                return a.name.localeCompare(b.name);
            });
            setKommuner(kommunerBody.codes.map(({name, code}) => ({label: name, value: code})));
        }
        if (fylke != -1) {
            getKommuner();
        } else {
            setKommuner([]);
            setValgtKommune('');
        }
    }, [fylke]);

    return (
        <div>
            <label htmlFor='kommuneSelect'>Kommune</label>
            <select id='kommuneSelect' onChange={(e) => setValgtKommune(e.target.value)} defaultValue={-1} className='form-control' disabled={disabled}>
                <option key={-1} value={''}>
                    Velg kommune
                </option>
                {kommuner.map(({ label, value }) => (
                <option key={value} value={value}>
                    {label}
                </option>
                ))}
            </select>
        </div>
    );
}

export default Kommuner;