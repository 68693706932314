import React, { useState, useEffect} from 'react';

const Fylker = ({disabled, setValgtFylke}) => {

    const [fylker, setFylker] = useState([]);

    function formatYMD(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth()+1).toString();
        var dd  = date.getDate().toString();
        return yyyy + '-' + (mm[1]?mm:'0'+mm[0]) + '-' + (dd[1]?dd:'0'+dd[0]);
    }

    useEffect(() => {
        async function getFylker() {
          const response = await fetch('https://data.ssb.no/api/klass/v1/classifications/104/codesAt.json?date=' + formatYMD(new Date()));
          const fylkerBody = await response.json();
          fylkerBody.codes.sort(function(a,b) {
            return a.name.localeCompare(b.name);
        });
          setFylker(fylkerBody.codes.map(({name, code}) => ({label: name, value: code})));
        }
        getFylker();
    }, []);

    return (
        <div>
            <label htmlFor='fylkeSelect'>Fylke</label>
            <select id='fylkeSelect' onChange={(e) => setValgtFylke(e.target.value)} defaultValue={-1} className='form-control' disabled={disabled}>
            <option key={-1} value={-1}>
                Alle fylker
            </option>
            {fylker.map(({ label, value }) => (
                <option key={value} value={value}>
                {label}
                </option>
            ))}
            </select>
        </div>
    );
}

export default Fylker;