import React from 'react';

const Sokenavigasjon = ({page, pageCount, totalCount, navigate}) => {

    return (
        <div>
            <nav className="row">
              <div className="col-12" id="searchNav" >
                  <button className="btn btn-secondary" onClick={() => navigate(-1)} disabled={page == 0 }>Forrige side</button>
                  <button className="btn btn-secondary" onClick={() => navigate(1)} disabled={page >= pageCount-1}>Neste side</button>
                  <div className='nowrap' style={{display: (pageCount > 0 ? 'block' : 'none')}}>Side {page + 1} av {pageCount.toLocaleString()} ({totalCount.toLocaleString()} enheter totalt)</div>
              </div>
            </nav>
        </div>
    );
}

export default Sokenavigasjon;